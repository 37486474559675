// import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
// Utils
import jwtDecode from 'jwt-decode';
import auth from '../utils/auth/permissionRules';
import store from '../store/index';
import axios from 'axios';
import constants from '@/assets/constants';
// Views
// - executive
const FleetCard = () => import('@/views/executive/FleetCard');
const FleetPie = () => import('@/views/executive/FleetPie');
const Naics = () => import('@/views/executive/Naics');
const TruckDetails = () => import('@/views/executive/TruckDetails');
const ProfitLoss = () => import('@/views/executive/ProfitLoss');
const Trucks = () => import('@/views/dashboard/Trucks');
const OwnerStats = () => import('@/views/executive/OwnerStats');
const ManheimListings = () => import('@/views/executive/ManheimListings');
// - reporting
const MonthsInFleet = () => import('@/views/executive/MonthsInFleet');
const CompanyReport = () => import('@/views/executive/CompanyReport');
const NewCompany = () => import('@/views/executive/NewCompany');
const AuctionReport = () => import('@/views/reporting/AuctionReport');
const MileageReport = () => import('@/views/reporting/MileageReport');
const CreditMemos = () => import('@/views/reporting/CreditMemos');
const CMOpenDetail = () => import('@/views/reporting/CMOpenDetail');
const CMAllDetail = () => import('@/views/reporting/CMAllDetail');
const InvoiceReport = () => import('@/views/reporting/InvoiceReport');
const DaysInStatus = () => import('@/views/reporting/DaysInStatus');
const DaysOnRent = () => import('@/views/reporting/DaysOnRent');
const CustomReport = () => import('@/views/reporting/CustomQuery');
const ActivityTracker = () => import('@/views/reporting/ActivityTracker');
const BMRReport = () => import('@/views/reporting/BMRReport');
const StatusDiff = () => import('@/views/reporting/StatusDiff');
const LotAuctionCount = () => import('@/views/dashboard/LotAuctionCount');
const OnRentOptionalServices = () => import('@/views/dashboard/OnRentOptionalServices');
const TruckCountByState = () => import('@/views/dashboard/TruckCountByState');
const TruckCountByStateG = () => import('@/views/dashboard/TruckCountByStateG');
const DailyCounts = () => import('@/views/reporting/DailyCounts');
// - forms
const DamageForm = () => import('@/views/forms/DamageForm');
const InspectDamages = () => import('@/views/forms/InspectDamages');
const OrderForm = () => import('@/views/forms/OrderForm');
const OffRentForm = () => import('@/views/forms/OffRentForm');
const Register = () => import('@/views/forms/Register');
const TradeForm = () => import('@/views/forms/TradeForm');
// - Tag Process
const Transport = () => import('@/views/dashboard/Transport');
const Onboarding = () => import('@/views/dashboard/Onboarding');
const OffBoarding = () => import('@/views/dashboard/OffBoarding');
const AccountsReceivable = () => import('@/views/dashboard/AccountsReceivable');
const ClientRelations = () => import('@/views/dashboard/ClientRelations');
const Recon = () => import('@/views/dashboard/Recon');
const Sales = () => import('@/views/dashboard/Sales');
const Title = () => import('@/views/dashboard/Titles');
// - general
const EmployeeManagement = () => import('@/views/general/EmployeeManagement');
const Overview = () => import('@/views/general/Overview');
const ClientInfo = () => import('@/views/general/ClientInfo');
const Login = () => import('@/views/general/Login');
const NotFound = () => import('@/views/general/NotFound');
const Home = () => import('@/views/general/Home');
const HomeApps = () => import('@/views/general/HomeApps');
const Profile = () => import('@/views/general/Profile');
const PasswordResetExternal = () => import('@/views/general/PasswordResetExternal');
const Documentation = () => import('@/views/dashboard/Documentation');
const StatusCount = () => import('@/views/general/StatusCount');
const Billing = () => import('@/views/general/Billing');

// Vue.use(router);

const routes = [
  {
    path: '/reset/:encryption',
    name: 'passwordResetExternal',
    component: PasswordResetExternal,
  },
  {
    path: '/trucks',
    name: 'trucks',
    component: Trucks,
  },
  {
    path: '/',
    // redirect: { name: 'login' }
    beforeEnter(to, from, next) {
      window.location.href = "https://www.barcotrucks.com/portal";
    }
  },
  {
    path: '/employee-login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/employee-management',
    name: 'employee-management',
    component: EmployeeManagement,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/home-apps',
    name: 'home-apps',
    component: HomeApps,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/client-relations',
    name: 'client-relations',
    component: ClientRelations,
  },
  {
    path: '/transport',
    name: 'transport',
    component: Transport,
  },
  {
    path: '/fleetcard',
    name: 'fleetcard',
    component: FleetCard,
  },
  {
    path: '/industry',
    name: 'industry',
    component: Naics,
  },
  {
    path: '/monthsinfleet',
    name: 'monthsinfleet',
    component: MonthsInFleet,
  },
  {
    path: '/fleetpie',
    name: 'fleetpie',
    component: FleetPie,
  },
  {
    path: '/damageform',
    name: 'damageForm',
    component: DamageForm,
  },
  {
    path: '/damageinspection',
    name: 'damageInspection',
    component: InspectDamages,
  },
  {
    path: '/company-report',
    name: 'company-report',
    component: CompanyReport,
  },
  {
    path: '/auction-report',
    name: 'auction-report',
    component: AuctionReport,
  },
  {
    path: '/mileage-report',
    name: 'mileage-report',
    component: MileageReport,
  },
  {
    path: '/status-diff',
    name: 'status-diff',
    component: StatusDiff,
  },
  {
    path: '/lot-auction-count',
    name: 'lot-auction-count',
    component: LotAuctionCount,
  },
  {
    path: '/on-rent-optional-services',
    name: 'on-rent-optional-services',
    component: OnRentOptionalServices,
  },
  {
    path: '/truck-count-by-state',
    name: 'truck-count-by-state',
    component: TruckCountByState,
  },
  {
    path: '/truck-count-by-state-new',
    name: 'truck-count-by-state-new',
    component: TruckCountByStateG,
  },
  {
    path: '/billing',
    name: 'billing',
    component: Billing
  },
  {
    path: '/new-company',
    name: 'new-company',
    component: NewCompany,
  },
  {
    path: '/order-form',
    name: 'orderForm',
    component: OrderForm,
  },
  {
    path: '/trade-form',
    name: 'tradeForm',
    component: TradeForm,
  },
  {
    path: '/docs',
    name: 'documentation',
    component: Documentation,
  },
  {
    path: '/off-rent-form',
    name: 'offRentForm',
    component: OffRentForm,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: Onboarding,
  },
  {
    path: '/offboarding',
    name: 'offboarding',
    component: OffBoarding,
  },
  {
    path: '/accounts-receivable',
    name: 'accounts-receivable',
    component: AccountsReceivable,
  },
  {
    path: '/recon',
    name: 'recon',
    component: Recon,
  },
  {
    path: '/sales',
    name: 'sales',
    component: Sales,
  },
  {
    path: '/titles',
    name: 'titles',
    component: Title,
  },
  {
    path: '/overview',
    name: 'overview',
    component: Overview,
  },
  {
    path: '/client-info',
    name: 'client-info',
    component: ClientInfo
  },
  {
    path: '/credit-memos',
    name: 'credit-memos',
    component: CreditMemos,
  },
  {
    path: '/cm-open-detail',
    name: 'cm-open-detail',
    component: CMOpenDetail,
  },
  {
    path: '/cm-all-detail',
    name: 'cm-all-detail',
    component: CMAllDetail,
  },
  {
    path: '/invoice-report',
    name: 'invoice-report',
    component: InvoiceReport,
  },
  {
    path: '/days-in-status-report',
    name: 'days-in-status-report',
    component: DaysInStatus,
  },
  {
    path: '/days-on-rent',
    name: 'days-on-rent-report',
    component: DaysOnRent,
  },
  {
    path: '/custom-report',
    name: 'custom-report',
    component: CustomReport,
  },
  {
    path: '/activity-tracker',
    name: 'activity-tracker',
    component: ActivityTracker,
  },
  {
    path: '/statuscount',
    name: 'statuscount',
    component: StatusCount,
  },
  {
    path: '/daily-counts',
    name: 'dailycounts',
    component: DailyCounts,
  },
  {
    path: '/truck-details',
    name: 'truck-details',
    component: TruckDetails,
  },
  {
    path: '/profit-loss',
    name: 'profit-loss',
    component: ProfitLoss,
  },
  {
    path: '/bmr',
    name: 'bmr',
    component: BMRReport,
  },
  {
    path: '/owners-stats',
    name: 'owners-stats',
    component: OwnerStats,
  },
  {
    path: '/manheim-listings',
    name: 'manheim-listings',
    component: ManheimListings,
  },
  // ! the '*' must be the last path for error handling
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Route protection
router.beforeEach(async (to, from, next) => {
  const localToken = localStorage.getItem('token');
  // - Handles unprotected routes
  if (await auth.permittedRoute(to.name)) {
    return next();
  }
  // - Handles allAccess routes
  if ((!auth.permittedRoute(to.name)) && !localToken) {
    return next('/');
  }

  // - Handles new user (no token)
  if (to.name !== 'employee-login' && !localToken && window.location.hostname == 'barcodashboard.com') {
    // return next('/');
    window.location.href = 'https://www.barcotrucks.com/portal';
  }
  if (to.name !== 'employee-login' && !localToken && to.matched.length === 0 && to.name !== 'notFound') {
    next({ name: 'notFound' });
    return;
  }
  if (window.location.hostname != 'barcodashboard.com') {
    return next();
  }
  if (to.name === 'employee-login' && !localToken) {
    return next();
  }

  // - Handles returning user (token expired)
  if (localToken) {
    const unwrappedToken = await jwtDecode(localToken.split(' ')[1]);
    if (unwrappedToken.exp * 1000 < Date.now()) {
      return store.dispatch('openModal');
    }
  }
  
  // - Handles user protected routes
  if (!(await auth.permittedRoute(to.name))) {
    next({ name: 'notFound' });
    return;
  }
  next();
});
// Send page visit data to the server
router.afterEach((to, from) => {
  console.log('post track');
  axios.post(`${constants.BASE_URL}/api/track`, {
    Page: to.path,
    Timestamp: new Date().toISOString()
  });
});

export default router;
