import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue';
import './utils/helpers/axiosInterceptors';
import VueTheMask from 'vue-the-mask';
import vueDebounce from 'vue-debounce';

import SocketPlugin from './utils/sockets/index';

import VueDatamaps from 'vue-datamaps';
import vueCountryRegionSelect from 'vue3-country-region-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import USA from '@svg-maps/usa';

import { PublicClientApplication } from '@azure/msal-browser';
import createAuthMiddleware from './utils/auth/authMiddleware';

// CSS
import './assets/scss/index.scss';
// CSS FrameWorks
// import { BFormGroup, BFormRadio, BFormRadioGroup, BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { createBootstrap } from 'bootstrap-vue-next';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'vue3-tooltip/tooltip.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faCopy,
  faEye,
  faEyeSlash,
  faTimes,
  faBars,
  faPencilAlt,
  faAngleLeft,
  faSearch,
  faEllipsisV,
  faChevronRight,
  faUserPlus,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
  faCheckCircle,
  faSyncAlt,
  faEdit,
  faBell,
  faUndo,
  faUndoAlt,
  faFilter,
  faSquare,
  faUnlock,
  faLock,
  faCheck,
  faCheckSquare,
  faUserCircle,
  faArrowRight,
  faFileCsv,
  faRemoveFormat,
  faTrashAlt,
  faRecycle,
  faAngleDown,
  faSort,
  faEnvelopeOpen,
  faDownload,
  faRandom
} from '@fortawesome/free-solid-svg-icons';
import {
  faMicrosoft
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Vue.use(FontAwesomeIcon);
// Animations
// import VueScrollTo from 'vue-scrollto';
import VueFeather from 'vue-feather';

// Charts and Effects
// import imageViewer from 'vue-image-viewer';
import VueApexCharts from 'vue3-apexcharts';

// Vue Helpers
// import VueCurrencyFilter from 'vue-currency-filter';
import router from './router';
import constants from './assets/constants';
import moment from 'moment';
import 'moment/locale/fr'
import vueMoment from '@rah-emil/vue-moment';
import { TooltipDirective, TooltipComponent } from 'vue3-tooltip';

// Vue.config.productionTip = false;
// Vue.configureCompat({
//   WATCH_ARRAY: false,
//   ATTR_FALSE_VALUE: false
// });

// Vue.use(require('vue-moment'));
// let moment = require('moment');
// Vue.use(VueMoment, { moment });

// Vue.use(VueTheMask);
// Vue.use(vueDebounce);
// Vue.use(vueCountryRegionSelect)

// - ============ SOCKETS ============
import store from './store';
// Vue.use(
//   new VueSocketIO({
//     connection: `${constants.BASE_URL}`,
//     vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_',
//     },
//   })
// );
library.add(
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faCopy,
  faEye,
  faEyeSlash,
  faTimes,
  faBars,
  faPencilAlt,
  faAngleLeft,
  faSearch,
  faEllipsisV,
  faChevronRight,
  faUserPlus,
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
  faCheckCircle,
  faSyncAlt,
  faEdit,
  faBell,
  faUndo,
  faUndoAlt,
  faFilter,
  faSquare,
  faUnlock,
  faLock,
  faCheck,
  faCheckSquare,
  faUserCircle,
  faArrowRight,
  faFileCsv,
  faRemoveFormat,
  faTrashAlt,
  faRecycle,
  faAngleDown,
  faSort,
  faEnvelopeOpen,
  faDownload,
  faRandom,
  faMicrosoft
);
// Vue.use(VueScrollTo);
// Vue.use(imageViewer);

// export const EventBus = new Vue();

// createApp(App).use(router).use(store).mount('#app');

const msalConfig = {
  auth: {
    clientId: '19e95358-dbdc-479c-80df-9c1aafc48116',
    authority: 'https://login.microsoftonline.com/4cd0c202-c0bf-4548-b7ef-1b6f20c8147f',
    redirectUri: window.location.hostname == 'www.barcodashboard.com' ? 'https://www.barcodashboard.com' : window.location.hostname == 'barcodashboard.com' ? 'https://barcodashboard.com' : 
      window.location.hostname == 'test.barcodashboard.com' ? 'https://test.barcodashboard.com' : 'http://localhost:8080'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

const msalInstance = new PublicClientApplication(msalConfig);
async function initializeApp() {
  await msalInstance.initialize();

  moment.locale('fr');

  const app = createApp(App);
  app.config.globalProperties.$msalInstance = msalInstance;
// Handle the redirect response and process it
msalInstance.handleRedirectPromise()
  .then((response) => {
    if (response) {
      console.log('Redirect login successful:', response);
    }
  })
  .catch((error) => {
    console.error('Redirect login error:', error);
  });
  router.beforeEach(createAuthMiddleware(app));
  // import VueSocketIO from './utils/sockets/index';
  // Vue.use(VueCurrencyFilter, {
  //   symbol: '$',
  //   thousandsSeparator: ',',
  //   fractionCount: 2,
  //   fractionSeparator: '.',
  //   symbolPosition: 'front',
  //   symbolSpacing: false,
  // })
  // app.config.globalProperties.$moment = moment;
  app.use(store);
  app.use(router);
  app.use(vueMoment, { moment });
  app.use(VueDatamaps);
  app.use(VueTheMask);
  app.use(vueDebounce);
  app.use(vueCountryRegionSelect);
  app.use(VueApexCharts)
  // .use(BootstrapVue)
  // .use(IconsPlugin)
  // app.use(
  //   new VueSocketIO({
  //     connection: `${constants.BASE_URL}`,
  //     vuex: {
  //       store,
  //       actionPrefix: 'SOCKET_',
  //       mutationPrefix: 'SOCKET_',
  //     },
  //   })
  // );
  app.use(SocketPlugin, {
    connection: `${constants.BASE_URL}`,
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_',
    },
    debug: true,
    options: {
      useConnectionNamespace: false,
      namespaceName: 'defaultNamespace',
    }
  });
  // const socketOptions = {
  //   connection: `${constants.BASE_URL}`,
  //   options: {}
  // }
  // app.use(VueSocketIO, socketOptions);
  // app.use(VueSocketIOExt, socket, {
  //   store,
  //   actionPrefix: 'SOCKET_',
  //   mutationPrefix: 'SOCKET_',
  // });

  app.use(createBootstrap({components: true, directives: true}));
  // app.component('region-select', vueCountryRegionSelect);
  // app.component('country-select', vueCountryRegionSelect);
  app.directive('tooltip', TooltipDirective);
  app.component('tooltip', TooltipComponent);
  app.component('datepicker', VueDatePicker);
  app.component('svg-map', USA)
  // app.component('apexchart', VueApexCharts);
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component(VueFeather.name, VueFeather);
  // app.provide('VueMoment', VueMoment);
  // app.component('b-form-group', BFormGroup);
  // app.component('b-form-radio', BFormRadio);
  // app.component('b-form-radio-group', BFormRadioGroup);
  // app.config.globalProperties.$socket = VueSocketIO;
  // configureCompat({
  //   // default everything to Vue 2 behavior
  //   MODE: 2,

  //   // opt-in to Vue 3 behavior for non-compiler features
  //   // FEATURE_ID_A: true,
  //   // FEATURE_ID_B: true,
  //   // __VUE_PROD_HYDRATION_MISMATCH_DETAILS__: false,
  //   WATCH_ARRAY: false,
  //   ATTR_FALSE_VALUE: false,
  //   // INSTANCE_EVENT_EMITTER: false,
  //   // COMPONENT_FUNCTIONAL: false,
  // });
  app.mount('#app');
  // store.dispatch('trucks/connect');
  if (module.hot) {
    module.hot.accept();
  }

  store.dispatch('connect');
}

initializeApp().catch(console.error);

// new Vue({
//   router,
//   store,
//   render(h) {
//     return h(App);
//   },
// }).$mount('#app');
