<template>
  <div id="modal__editCreditMemo">
    <h1 id="edit-cm__title">
      {{ editForm.action == 'Create' ? 'Edit Credit Memo' : editForm.action == 'Apply' ? 
				'Edit Applied Credit Memo' : 'Credit Memo - Don\'t Edit' }}
    </h1>
		<hr class="header-hr">
    <div id="edit-cm-form-container">
			<BFormGroup>
				<BRow align-h="between">
					<BCol sm="1.5">
						<p>*Company Number: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="editForm.companyCode" required size="sm" class="cm-input" :disabled="editForm.action == 'Apply'"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>*Invoice/Claim #: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="editForm.invoiceClaim" required size="sm" class="cm-input" :disabled="editForm.action == 'Apply'"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>*Credit Memo Date: </p>
					</BCol>
					<BCol>
						<datepicker
							class="datepicker"
							name="cmDate"
							week-start="0"
							:bootstrap-styling="true"
							v-model="editForm.creditMemoDate"
							:format="format"
							:disabled="editForm.action == 'Apply'"
							required
							:enable-time-picker="false"
            	:timezone="{ timezone: 'UTC', exactMatch: true }"
							ignore-time-validation
							auto-apply
							vertical
							arrow-navigation
						></datepicker>
						<!-- <BFormInput v-model="editForm.credtMemoDate" size="sm"></BFormInput> -->
					</BCol>
					<BCol sm="1.5">
						<p>Credit Memo Type: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="editForm.creditMemoType" size="sm" class="cm-input" :disabled="editForm.action == 'Apply'"></BFormInput>
					</BCol>
				</BRow>
				<BRow align-h="between">
					<BCol sm="1.5">
						<p>*Credit Memo Source: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="editForm.creditMemoSource" required size="sm" class="cm-input" :disabled="editForm.action == 'Apply'"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>Credit Memo Description: </p>
					</BCol>
					<BCol>
						<!-- <BFormInput v-model="editForm.creditMemoDescription" size="sm" class="cm-input"></BFormInput> -->
						<BFormTextarea v-model="editForm.creditMemoDescription" size="sm" class="cm-textarea" :disabled="editForm.action == 'Apply'"></BFormTextarea>
					</BCol>
					<BCol sm="1.5">
						<p>Credit Memo Notes: </p>
					</BCol>
					<BCol>
						<!-- <BFormInput v-model="editForm.creditMemoNotes" size="sm" class="cm-input"></BFormInput> -->
						<BFormTextarea v-model="editForm.creditMemoNotes" size="sm" class="cm-textarea" :disabled="editForm.action == 'Apply'"></BFormTextarea>
					</BCol>
				</BRow>
				<div class="cm-details-container" v-if="editForm.action != 'Apply'">
					<b>Credit Memo Details:</b>
					<div class="cm-details-div">
						<div
						v-for="(vin, index) in editForm.vins"
						:key="vin.id"
						class="cm-vin-row"
						>
						<font-awesome-icon
							@click="handleRemoveVin(index, vin.relatedInvoice, vin.unitNumber)"
							class="edit__minus"
							icon="minus-circle"
						/>
						<b>*Related Invoice:</b>
						<input
							required
							v-model="vin.relatedInvoice"							
						/>
						<b>Unit Number:</b>
						<input
							required
							v-mask="'XXXXXXXX'"
							@input="e => ((e.target.value = e.target.value.toUpperCase()))"
							v-model="vin.unitNumber"
						/>
						<b>*Amount:</b>
						<input
							required
							v-model="vin.amount"
						/>
						<b>PO:</b>
						<input
							v-model="vin.po"
						/>
						<b>*Note:</b>
						<textarea
							class="invoice-note"
              required
							v-model="vin.note"
						/>
						</div>
						<div class="edit__btns-container">
						<div @click="handleAddVin" id="edit__addVin">
							<font-awesome-icon class="edit__plus" icon="plus-circle" />
							<p>Add New Vin</p>
						</div>
						</div>
					</div>
				</div>
				<div class="cm-details-container" v-else-if="editForm.action == 'Apply'">
					<BRow align-h="between" class="edit-applied-details-row">
						<BCol sm="6"><b>CM Applied Details:</b></BCol>
						<BCol sm="6"><p class="align-right">Credits Available: {{AvailableCredit}}</p></BCol>
					</BRow>
					<div class="cm-details-div">
						<div
						v-for="(vin) in editForm.vins"
						:key="vin.id"
						class="cm-vin-row"
						>
						<!-- <font-awesome-icon
							@click="handleRemoveVin(index, vin.relatedInvoice, vin.unitNumber)"
							class="edit__minus"
							icon="minus-circle"
						/> -->
						<b>*Invoice Applied To:</b>
						<input
							required
							v-model="vin.relatedInvoice"
						/>
						<b>*Amount:</b>
						<input
							required
							v-model="vin.amount"
						/>
						<b>*Date Applied:</b>
						<input
							v-model="vin.appliedDate"
						/>
						<b>*Note:</b>
						<textarea
							class="invoice-note"
              required
							v-model="vin.note"
						/>
						</div>
						<!-- <p>Total: {{totalAvailable}}</p> -->
					</div>
				</div>
			</BFormGroup>
      <div class="edit-cm__footer">
				<div v-if="successMessage != ''">
					<p class="success">{{ successMessage }}</p>
				</div>
				<div v-if="errorMessage != ''">
					<p class="error">{{ errorMessage }}</p>
				</div>
        <BButton
          v-if="!spinners.submitting"
          id="edit-btn"
          size="sm"
          variant="outline-primary"
          @click="editCreditMemo"
          >Save</BButton
        >
        <div v-else id="edit-btn">
          <BSpinner id="b-spinner--edit-btn__spinner"></BSpinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
// import Datepicker from 'vuejs3-datepicker';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EditCreditMemo',
  components: {},
  data() {
    return {
      spinners: {
        submitting: false,
			},
			editForm: {
        creditMemoID: '',
				action: '',
				companyCode: '',
				creditMemoDate: new Date(),
				creditMemoType: 'OP',
				invoiceClaim: '',
				creditMemoSource: '',
				creditMemoDescription: '',
				creditMemoNotes: '',
				vins: [{
					relatedInvoice: '',
					originalInvoice: '',
					unitNumber: '',
					amount: '',
					po: '',
					note: '',
					appliedDate: '',
				}],
				removedInvoices: [],
			},
			format: 'MM/dd/yyyy',
			successMessage: '',
      errorMessage: '',
      currentEdit: {},
			AvailableCredit: '',
			totalAvailable: 0,
    };
  },
  computed: mapGetters(['getModalData', 'getCurrentNoteOrder']),
  mounted() {
    this.currentEdit = this.getCurrentNoteOrder;
		this.editForm.action = this.currentEdit.Action;
    this.editForm.creditMemoID = this.currentEdit.CreditMemoID;
    this.editForm.companyCode = this.currentEdit.CompanyCode;
    this.editForm.invoiceClaim = this.currentEdit.InvoiceClaimNumber;
    this.editForm.creditMemoDate = this.currentEdit.CreditMemoDate;
    this.editForm.creditMemoType = this.currentEdit.CreditMemoType;
    this.editForm.creditMemoSource = this.currentEdit.CreditMemoSource;
    this.editForm.creditMemoDescription = this.currentEdit.CreditMemoDescription;
    this.editForm.creditMemoNotes = this.currentEdit.CreditMemoNotes;
    let editVins = this.currentEdit.VinDates;
    // this.editForm.vins.length = this.currentEdit.Vins.split(',').length;
    for (let i = 0; i < editVins.length; i++) {
      if (i == 0) {
        this.editForm.vins[0].relatedInvoice = editVins[i].split(' - ')[0];
		this.editForm.vins[0].originalInvoice = editVins[i].split(' - ')[0];
        this.editForm.vins[0].unitNumber = editVins[i].split(' - ')[1];
        this.editForm.vins[0].amount = editVins[i].split(' - ')[2];
        this.editForm.vins[0].po = editVins[i].split(' - ')[3];
		if (editVins[i].split(' - ')[4]) {
              if (editVins[i].split(' - ').length > 5) {
                for (let z = 4; z < editVins[i].split(' - ').length - 1; z++) {
                  this.editForm.vins[0].note += editVins[i].split(' - ')[z].split(' ~ ')[0].replaceAll('%$#', ',') + ' - ';
                }
              }
			this.editForm.vins[0].note += editVins[i].split(' - ')[editVins[i].split(' - ').length - 1].split(' ~ ')[0].replaceAll('%$#', ',');
		}
		this.editForm.vins[0].appliedDate = editVins[i].split(' ~ ')[1];
      } else {
		let theNote = '';
		if (editVins[i].split(' - ')[4]) {
              if (editVins[i].split(' - ').length > 5) {
                for (let z = 4; z < editVins[i].split(' - ').length - 1; z++) {
                  theNote += editVins[i].split(' - ')[z].split(' ~ ')[0].replaceAll('%$#', ',');
                }
              }
			theNote += editVins[i].split(' - ')[editVins[i].split(' - ').length - 1].split(' ~ ')[0].replaceAll('%$#', ',');
		}
        this.editForm.vins.push({
          relatedInvoice: editVins[i].split(' - ')[0],
		  originalInvoice: editVins[i].split(' - ')[0],
          unitNumber: editVins[i].split(' - ')[1],
          amount: editVins[i].split(' - ')[2],
          po: editVins[i].split(' - ')[3],
          note: theNote,//editVins[i].split(' - ')[4].split(' ~ ')[0].replaceAll('%$#', ','),
		  appliedDate: editVins[i].split(' ~ ')[1]
        })
      }
			this.totalAvailable += parseFloat(editVins[i].split(' - ')[2].split('$')[1]);
    }
		let code = this.currentEdit.CompanyCode;
		if (code != '') {
			axios
				.get(`${constants.BASE_URL}/api/credit-memo/find-company-credits/${code}`, {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				})
				.then(result => {
					let invoices = result.data[0].Invoices.split(',');
					for (let i = 0; i < invoices.length; i++) {
						if (invoices[i].includes(this.currentEdit.InvoiceClaimNumber)) {
							console.log('It finds')
							this.AvailableCredit = '$' + invoices[i].split('$')[1].split(' # ')[0];
						}
						if (i == invoices.length - 1 && this.AvailableCredit == '') {
							this.AvailableCredit = '$0.00/0.00';
						} 
					}
					this.totalAvailable += parseFloat(this.AvailableCredit.split('$')[1].split('/')[0]);
				})
				.catch(err => {
					// this.errorMessage = "Error, company number does not have any approved credits available."
					console.log('error: ', err);
				});
		}
  },
  methods: {
		...mapActions([]),
		editCreditMemo() {
			this.spinners.submitting = true;
			this.successMessage = '';
			this.errorMessage = '';
			if (this.editForm.companyCode == '' ||
					this.editForm.creditMemoDate == '' ||
					this.editForm.creditMemoType == '' ||
					this.editForm.invoiceClaim == '' ||
					this.editForm.creditMemoSource == '' ||
					this.editForm.creditMemoDescription == '') 
			{
				this.spinners.submitting = false;
				this.errorMessage = "Error, please verify you have filled out all required fields and try again.";
				return;
			}
			else {
				let sumEdited = 0;
				for (let x = 0; x < this.editForm.vins.length; x++) {
					sumEdited += parseFloat(this.editForm.vins[x].amount.split('$')[1]);
					if (x == this.editForm.vins.length - 1) {
						if (sumEdited > this.totalAvailable && this.editForm.action == 'Apply') {
							this.spinners.submitting = false;
							this.errorMessage = "Error, Amounts cannot be greater than original sum of applied amounts plus credits available.";
						} else {
							for (let i = 0; i < this.editForm.vins.length; i++) {
								if ((this.editForm.vins[i].related	== '' || this.editForm.vins[i].unitNumber == '' ||
										this.editForm.vins[i].amount == '' || this.editForm.vins[i].note == '') && this.editForm.action != 'Apply') {
									this.spinners.submitting = false;
									this.errorMessage = "Error, please verify you have filled out all required VIN fields and try again.";
									return;
								} 
								if (i == this.editForm.vins.length - 1) {
									for (let x = 0; x < this.editForm.removedInvoices.length; x++) {
										if (this.editForm.removedInvoices[x].InvoiceNumber == this.editForm.vins[i].relatedInvoice) {
											let tempIndex = this.editForm.removedInvoices.indexOf(this.editForm.vins[i].relatedInvoice);
											this.editForm.removedInvoices.splice(tempIndex, 1);
										}
									}
									if (this.editForm.action == 'Create') {
										axios
											.put(`${constants.BASE_URL}/api/credit-memo/edit-memo`, { ...this.editForm })
											.then(res => {
												this.spinners.submitting = false;
												let that = this;
												this.successMessage = 'Credit Memo edited successfully!'
												setTimeout(function(){ that.successMessage = '' }, 5000);
												this.$socket.emit('creditMemosRefresh');
												this.$socket.emit('creditMemosRefresh', 'user');
											})
											.catch(err => {
												this.spinners.submitting = false;
												console.log('err status: ', err.response.data.error);
												this.errorMessage = "Error, please verify you have filled out all required fields and try again."
											});
									} else if (this.editForm.action == 'Apply') {
										axios
											.put(`${constants.BASE_URL}/api/credit-memo/edit-applied-memo`, { ...this.editForm })
											.then(res => {
												this.spinners.submitting = false;
												let that = this;
												this.successMessage = 'Credit Memo edited successfully!'
												setTimeout(function(){ that.successMessage = '' }, 5000);
												this.$socket.emit('creditMemosRefresh');
												this.$socket.emit('creditMemosRefresh', 'user');
											})
											.catch(err => {
												this.spinners.submitting = false;
												console.log('err status: ', err.response.data.error);
												this.errorMessage = "Error, please verify you have filled out all required fields & there are no duplicates and try again."
											});
									}
								}
							} 
						} 
					}
				}
			}
		},
		handleAddVin() {
			this.editForm.vins.push({
				relatedInvoice: '',
				originalInvoice: '',
				unitNumber: '',
				amount: '',
				po: '',
				note: '',
			})
		},
    handleRemoveVin(index, InvoiceNumber, UnitNumber) {
			this.editForm.vins.splice(index, 1);
			this.editForm.removedInvoices.push({InvoiceNumber, UnitNumber});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__editCreditMemo {
  min-height: 100%;
  min-width: 100%;
}

.modal-lg {
	max-width: 1000px;
}

#edit-cm__title {
  position: relative;
  margin-top: 0px;
  top: -20px;
  margin-left: 0;
	margin-right: 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

#edit-cm-form-container {
  width: 90%;
  margin: auto;
	margin-top: 25px;
}

.edit-cm__footer {
  height: 14vh;
  width: 100%;
  @include F($d: column);
  textarea {
    outline: none;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    font-size: 0.9rem;
    color: #363636;
    font-weight: 500;
    padding: 5px 10px;
    resize: none;
    width: 100%;
    height: 90%;
    margin-bottom: 10px;
  }
  #edit-btn {
    margin: 5px 0 10px auto;
  }

  #b-spinner--edit-btn__spinner {
    color: #5aaaff;
  }
}

.cm-input {
	margin-bottom: 10px;
	height: calc(1.5em + .75em + 10px);
}

.cm-textarea {
	margin-bottom: 10px;
}

p {
	font-size: 1rem;
}

.cm-details-container {
  @include F(center, flex-start, column);
  width: 101%;
  margin: 10px -15px;
  b {
    margin: 0 5px 0 0;
  }
  input {
    max-width: 90% !important;
    background: none;
		border: 1px solid lightgray;
		border-radius: 5px;
		margin-right: 5px;
		padding: 5px;
    outline: none;
  }
}

.cm-details-div {
	@include F(center, flex-start, column);
	width: 100%;
}

.cm-vin-row {
  @include F(flex-start, center);
  background: #eee;
  margin: 5px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
  }
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__btns-container {
  margin: 10px 0 0;
  padding: 0 10px 0 0;
  @include F($j: space-between);
  width: 100%;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  p {
    font-weight: 600;
  }
}

.invoice-note {
	width: 200px;
	background: #eee;
	border: 1px solid lightgray;
}

.success {
	color: green;
}

.error {
	color: red;
}

.align-right {
	margin-right: 0px;
	text-align: right;
}

.edit-applied-details-row {
	width: 101%;
}
</style>
