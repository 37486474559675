<template>
  <div id="modal__createCreditMemo">
    <div id="create-cm-form-container">
			<BFormGroup>
				<BRow align-h="between">
					<BCol sm="1.5">
						<p>*Company Number: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="createForm.companyCode" required size="sm" class="cm-input"
							v-mask="'#####'"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>*Invoice/Claim #: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="createForm.invoiceClaim" required size="sm" class="cm-input" @blur="checkInvoiceClaim()"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>*Credit Memo Date: </p>
					</BCol>
					<BCol>
						<datepicker
							class="datepicker"
							name="cmDate"
							week-start="0"
							:bootstrap-styling="true"
							v-model="createForm.creditMemoDate"
							:format="format"
							:enable-time-picker="false"
            	:timezone="{ timezone: 'UTC', exactMatch: true }"
							ignore-time-validation
							auto-apply
							vertical
							arrow-navigation
							required
						></datepicker>
						<!-- <BFormInput v-model="createForm.credtMemoDate" size="sm"></BFormInput> -->
					</BCol>
					<BCol sm="1.5">
						<p>*Credit Memo Type: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="createForm.creditMemoType" required size="sm" class="cm-input"></BFormInput>
					</BCol>
				</BRow>
				<BRow align-h="between">
					<BCol sm="1.5">
						<p>*Credit Memo Source: </p>
					</BCol>
					<BCol>
						<BFormInput v-model="createForm.creditMemoSource" required size="sm" class="cm-input"></BFormInput>
					</BCol>
					<BCol sm="1.5">
						<p>*Credit Memo Description: </p>
					</BCol>
					<BCol>
						<!-- <BFormInput v-model="createForm.creditMemoDescription" size="sm" class="cm-input"></BFormInput> -->
						<BFormTextarea v-model="createForm.creditMemoDescription" required size="sm" class="cm-textarea"></BFormTextarea>
					</BCol>
					<BCol sm="1.5">
						<p>Credit Memo Notes: </p>
					</BCol>
					<BCol>
						<!-- <BFormInput v-model="createForm.creditMemoNotes" size="sm" class="cm-input"></BFormInput> -->
						<BFormTextarea v-model="createForm.creditMemoNotes" size="sm" class="cm-textarea"></BFormTextarea>
					</BCol>
				</BRow>
				<div class="cm-details-container">
					<b>Credit Memo Details:</b>
					<div class="cm-details-div">
						<div
						v-for="(vin, index) in createForm.vins"
						:key="vin.id"
						class="cm-vin-row"
						>
						<font-awesome-icon
							@click="handleRemoveVin(index)"
							class="edit__minus"
							icon="minus-circle"
						/>
						<b>*Related Invoice:</b>
						<input
							required
							v-model="vin.relatedInvoice"
						/>
						<b>*Unit Number:</b>
						<input
							required
							v-mask="'XXXXXXXX'"
							@input="e => ((e.target.value = e.target.value.toUpperCase()))"
							v-model="vin.unitNumber"
						/>
						<b>*Amount:</b>
						<input
							required
							v-model="vin.amount"
						/>
						<b>PO:</b>
						<input
							v-model="vin.po"
						/>
						<b>*Note:</b>
						<textarea
							class="invoice-note"
							required
							v-model="vin.note"
						/>
						</div>
						<div class="edit__btns-container">
						<div @click="handleAddVin" id="edit__addVin">
							<font-awesome-icon class="edit__plus" icon="plus-circle" />
							<p>Add Additional Invoice</p>
						</div>
						</div>
					</div>
				</div>
			</BFormGroup>
      <div class="create-cm__footer">
				<div v-if="successMessage != ''">
					<p class="success">{{ successMessage }}</p>
				</div>
				<div v-if="errorMessage != ''">
					<p class="error">{{ errorMessage }}</p>
				</div>
        <BButton
          v-if="!spinners.submitting"
          id="create-btn"
          size="sm"
          variant="outline-primary"
          @click="createCreditMemo"
					:disabled="dupeInvoice"
          >Create</BButton
        >
        <div v-else id="create-btn">
          <BSpinner id="b-spinner--create-btn__spinner"></BSpinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
// import Datepicker from 'vuejs3-datepicker';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'createCreditMemo',
  components: {},
  data() {
    return {
      spinners: {
        submitting: false,
			},
			createForm: {
				companyCode: '',
				creditMemoDate: new Date(),
				creditMemoType: 'OP',
				invoiceClaim: '',
				creditMemoSource: '',
				creditMemoDescription: '',
				creditMemoNotes: '',
				vins: [{
					relatedInvoice: '',
					unitNumber: '',
					amount: '',
					po: '',
					note: '',
				}]
			},
			format: 'MM/dd/yyyy',
			successMessage: '',
			errorMessage: '',
			dupeInvoice: false,
    };
  },
  computed: mapGetters(['getModalData']),
  methods: {
		...mapActions([]),
		createCreditMemo() {
			this.spinners.submitting = true;
			this.successMessage = '';
			this.errorMessage = '';
			if (this.createForm.companyCode == '' ||
					this.createForm.creditMemoDate == '' ||
					this.createForm.creditMemoType == '' ||
					this.createForm.invoiceClaim == '' ||
					this.createForm.creditMemoSource == '' ||
					this.createForm.creditMemoDescription == '') 
			{
				this.spinners.submitting = false;
				this.errorMessage = "Error, please verify you have filled out all required fields and try again.";
				return;
			}
			else {
				for (let i = 0; i < this.createForm.vins.length; i++) {
					if (this.createForm.vins[i].relatedInvoice == '' || this.createForm.vins[i].unitNumber == '' ||
							this.createForm.vins[i].amount == '' || this.createForm.vins[i].note == '') {
						this.spinners.submitting = false;
						this.errorMessage = "Error, please verify you have filled out all required VIN fields and try again.";
						return;
					} 
					if (i == this.createForm.vins.length - 1) {
						axios
							.post(`${constants.BASE_URL}/api/credit-memo/create-new`, { ...this.createForm })
							.then(res => {
								this.spinners.submitting = false;
								let that = this;
								this.successMessage = 'Credit Memo created successfully!'
								setTimeout(function(){ that.successMessage = '' }, 5000);
								this.createForm.companyCode = '';
								this.createForm.creditMemoDate = new Date();
								this.createForm.creditMemoType = 'OP';
								this.createForm.invoiceClaim = '';
								this.createForm.creditMemoSource = '';
								this.createForm.creditMemoDescription = '';
								this.createForm.creditMemoNotes = '';
								this.createForm.vins = [{
									relatedInvoice: '',
									unitNumber: '',
									amount: '',
									po: '',
									note: '',
								}];
                this.$socket.emit('creditMemosRefresh');
                this.$socket.emit('creditMemosRefresh', 'user');
							})
							.catch(err => {
								this.spinners.submitting = false;
								console.log('err status: ', err.response.data.error);
								this.errorMessage = "Error, creating credit memo failed. Check for possible duplicate credit memo."
							});
					}
				}
			}
		},
		checkInvoiceClaim() {
			axios
				.get(`${constants.BASE_URL}/api/credit-memo/check-invoice-claim/${this.createForm.invoiceClaim}`, {
					headers: { Authorization: localStorage.getItem('token') },
				})
				.then(result => {
					if (result.data.length > 0) {
						this.errorMessage = 'Invoice/Claim # already exists. Please verify it is not a duplicate.';
						this.dupeInvoice = true;
					} else {
						this.errorMessage = '';
						this.dupeInvoice = false;
					}
				})
				.catch(err => {
					if (err.response && err.response.status === 403) {
						this.showLogOutModal = true;
					} else {
						console.log(err);
					}
				});
		},
		handleAddVin() {
			this.createForm.vins.push({
				relatedInvoice: '',
				unitNumber: '',
				amount: '',
				po: '',
				note: '',
			})
		},
    handleRemoveVin(index) {
      this.createForm.vins.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__createCreditMemo {
  min-height: 100%;
  min-width: 100%;
}

.modal-lg {
	max-width: 1000px;
}

#create-cm__title {
  margin: 10px 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

#create-cm-form-container {
  width: 90%;
  margin: 0 auto;
}

.create-cm__footer {
  height: 14vh;
  width: 100%;
  @include F($d: column);
  textarea {
    outline: none;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    font-size: 0.9rem;
    color: #363636;
    font-weight: 500;
    padding: 5px 10px;
    resize: none;
    width: 100%;
    height: 90%;
    margin-bottom: 10px;
  }
  #create-btn {
    margin: 5px 0 10px auto;
  }

  #b-spinner--create-btn__spinner {
    color: #5aaaff;
  }
}

.cm-input {
	margin-bottom: 10px;
	height: calc(1.5em + .75em + 10px);
}

.cm-textarea {
	margin-bottom: 10px;
}

p {
	font-size: 1rem;
}

.cm-details-container {
  @include F(center, flex-start, column);
  width: 101%;
  margin: 10px -15px;
  b {
    margin: 0 5px 0 0;
  }
  input {
    background: none;
		border: 1px solid lightgray;
		border-radius: 5px;
		margin-right: 5px;
		padding: 5px;
    outline: none;
  }
}

.cm-details-div {
	@include F(center, flex-start, column);
	width: 100%;
}

.cm-vin-row {
  @include F(flex-start, center);
  background: #eee;
  margin: 5px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
  }
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__btns-container {
  margin: 10px 0 0;
  padding: 0 10px 0 0;
  @include F($j: space-between);
  width: 100%;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  p {
    font-weight: 600;
  }
}

.invoice-note {
	width: 200px;
	background: #eee;
	border: 1px solid lightgray;
}

.success {
	color: green;
}

.error {
	color: red;
}

.cm-details-div input {
	max-width: 150px;
}
</style>
