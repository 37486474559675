<template>
    <div>
      <h2 class="department-create-center">{{ createEditName }} Department</h2>
      <hr class="modal-hr">
      <div class="d-block" >
        <BRow align-h="between" class="department-row">
          <BCol sm="4" lg="3">
            <span class="red">*</span><b>Department Name:</b>
          </BCol>
          <BCol sm="8" lg="9">
            <BInput v-model="DepartmentName" @blur="checkNames()"></BInput>
          </BCol>
          <BCol sm="4" lg="3" v-if="nameError != ''"></BCol>
          <BCol sm="8" lg="9" v-if="nameError != ''" class="red left-align">{{ nameError }}</BCol>
        </BRow>
        <div><hr>
          <BFormGroup v-if="standardPermissions.length > 0">
            <label class="font-weight-bold">Standard Permissions</label>
            <BRow align-h="start">
              <BCol sm="3" v-for="item in standardPermissions" :key="item">
                <BFormCheckbox
                v-model="selected"
                :value="item"
                >
                {{
                  permissions.find(permission => permission.PermissionID === item).PermissionName
                }}
              </BFormCheckbox>
            </BCol>
          </BRow>
          </BFormGroup><hr>
          <BFormGroup v-if="jobSpecificPermissions.length > 0">
            <label class="font-weight-bold">Job Specific Permissions</label>
            <BRow align-h="start">
              <BCol sm="3" v-for="item in jobSpecificPermissions" :key="item">
                <BFormCheckbox
                v-model="selected"
                :value="item"
                >
                {{
                  permissions.find(permission => permission.PermissionID === item).PermissionName
                }}
              </BFormCheckbox>
            </BCol>
          </BRow>
          </BFormGroup><hr>
          <BFormGroup v-if="managementPermissions.length > 0">
            <label class="font-weight-bold">Management Permissions</label>
            <BRow align-h="start">
              <BCol sm="3" v-for="item in managementPermissions" :key="item">
                <BFormCheckbox
                v-model="selected"
                :value="item"
                >
                {{
                  permissions.find(permission => permission.PermissionID === item).PermissionName
                }}
              </BFormCheckbox>
            </BCol>
          </BRow>
          </BFormGroup><hr>
          <BFormGroup v-if="reportingPermissions.length > 0">
            <label class="font-weight-bold">Reporting Permissions</label>
            <BRow align-h="start">
              <BCol sm="3" v-for="item in reportingPermissions" :key="item">
                <BFormCheckbox
                v-model="selected"
                :value="item"
                >
                {{
                  permissions.find(permission => permission.PermissionID === item).PermissionName
                }}
              </BFormCheckbox>
            </BCol>
          </BRow>
          </BFormGroup><hr>
          <BFormGroup v-if="executivePermissions.length > 0">
            <label class="font-weight-bold">Executive Permissions</label>
            <BRow align-h="start">
              <BCol sm="3" v-for="item in executivePermissions" :key="item">
                <BFormCheckbox
                v-model="selected"
                :value="item"
                >
                {{
                  permissions.find(permission => permission.PermissionID === item).PermissionName
                }}
              </BFormCheckbox>
            </BCol>
          </BRow>
          </BFormGroup><hr>
        </div>
      </div>
      <div>
        <p class="red" v-if="fieldCheck == false && missingError != ''">{{ missingError }}</p>
      </div>
      <div class="modal-option-btns">
        <BButton variant="light" size="sm" class="mt-3" @click="$emit('closeModal')">Close</BButton>
        <BButton v-if="createEditName == 'Create'" variant="success" size="sm" class="mt-3" :disabled='saveDisabled' @click="createDepartment()">
          <BSpinner small v-if="spinners.submit"></BSpinner>
          Save
        </BButton>
        <BButton v-if="createEditName == 'Edit'" variant="success" size="sm" class="mt-3" :disabled='saveDisabled' @click="editDepartment()">
          <BSpinner small v-if="spinners.submit"></BSpinner>
          Save
        </BButton>
      </div>
      <div v-if="createError !== ''">{{ createError }}</div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import axios from 'axios';
  import constants from '@/assets/constants';
  import EventBus from '../../utils/helpers/eventBus';
  
  export default {
    name: 'departmentCreate',
    props: ['departments'],
    components: {},
    data() {
      return {
        DepartmentName: '',
        createError: '',
        nameError: '',
				createEditName: 'Create',
        departmentList: [],
        departmentListReduced: [],
        fieldCheck: false,
        missingError: '',
        saveDisabled: false,
        spinners: {
          submit: false,
        },
        selected: [],
        permissions: [],
        standardPermissions: [],
        jobSpecificPermissions: [],
        managementPermissions: [],
        reportingPermissions: [],
        executivePermissions: [],
      };
    },
    mounted () {
      this.permissions = this.departments.Permissions;
      this.standardPermissions = this.departments.Standard;
      this.jobSpecificPermissions = this.departments.JobSpec;
      this.managementPermissions = this.departments.Management;
      this.reportingPermissions = this.departments.Reporting;
      this.executivePermissions = this.departments.Executive;
      if (this.departments.Department) {
        this.DepartmentName = this.departments.Department.DepartmentName;
        this.selected = this.departments.Department.PermissionIDs;
				this.departmentSelect(this.departments.Department);
				this.createEditName = 'Edit';
      };
      for (let i = 0; i < this.departments.length; i++) {
        this.departmentList.push(this.departments[i].name);
        let reducedName = this.departments[i].name.toLowerCase().trim();
        reducedName = reducedName.replaceAll(' ', '');
        reducedName = reducedName.replaceAll("'", "");
        reducedName = reducedName.replaceAll('&', 'and');
        reducedName = reducedName.replaceAll('.', '');
        reducedName = reducedName.replaceAll('-', '');
        reducedName = reducedName.replaceAll('llc', '');
        reducedName = reducedName.replaceAll('inc', '');
        this.departmentListReduced.push(reducedName);
      }
    },
    methods: {
      ...mapActions(['closeModal']),
      async createDepartment() {
        this.saveDisabled = true;
        this.spinners.submit = true;
        this.checkRequired();
        if (this.fieldCheck == true) {
          let departmentFields = {
            Name: this.DepartmentName,
            Permissions: this.selected
          }
          await axios
            .post(`${constants.BASE_URL}/api/create-department`, departmentFields)
            .then(res => {
              EventBus.emit('departmentCreated');
              this.$emit('closeModal');
            })
            .catch(err => {
              console.log(err);
              this.createError = err;
              let that = this;
              setTimeout(function() {
                that.createError = '';
              }, 5000);
            });
        } else {
          this.saveDisabled = false;
          return;
        }
      },
      async editDepartment() {
        this.saveDisabled = true;
        this.spinners.submit = true;
        this.checkRequired();
        if (this.fieldCheck == true) {
          let departmentFields = {
						OldName: this.departments.Department.DepartmentName,
            NewName: this.DepartmentName,
            Permissions: this.selected
          }
          await axios
            .put(`${constants.BASE_URL}/api/edit-department`, departmentFields)
            .then(res => {
              EventBus.emit('departmentCreated');
              this.$emit('closeModal');
            })
            .catch(err => {
              console.log(err);
              this.createError = err;
              let that = this;
              setTimeout(function() {
                that.createError = '';
              }, 5000);
            });
        } else {
          this.saveDisabled = false;
          return;
        }
      },
      checkRequired() {
        if (this.DepartmentName == '') {
          this.missingError = 'Please fill out all required fields.'
        } else {
          this.missingError = '';
          this.fieldCheck = true;
        }
        let that = this;
        setTimeout(function () {
          that.missingError = '';
        }, 5000);
        return;
      },
      checkNames() {
        let currentReduced = this.DepartmentName.toLowerCase().trim;
        currentReduced = currentReduced.replaceAll(' ', '');
        currentReduced = currentReduced.replaceAll("'", "");
        currentReduced = currentReduced.replaceAll('&', 'and');
        currentReduced = currentReduced.replaceAll('.', '');
        currentReduced = currentReduced.replaceAll('-', '');
        let item = '';
        if (this.departmentList.includes(this.DepartmentName) || this.departmentListReduced.includes(currentReduced)) {
          item = 'name';
        } else {
          item = '';
        }
        if (item != '') {
          this.nameError = 'Vendor ' + item + ' has already been used'
        } else {
          this.nameError = '';
        }
      },
			departmentSelect(val) {
				let PermIDs = val.PermissionIDs.split(',');
				this.selected = this.permissions.filter(permission => {
					if ((this.departments.UserPermissions[0] == 1 || this.departments.UserPermissions.includes(permission.PermissionName)) && PermIDs.includes(permission.PermissionID.toString())) return true;
				});
				this.selected = this.selected.map(permission => {
					return permission.PermissionID;
				});
			}
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/scss/index";
  
  #modal__departmentCreate {
    min-width: 100%;
    min-height: 100%;
    @include F($d: column);
  }

  .modal-option-btns {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 15px;
  }
  
  .department-row {
    margin-top: 10px;
  }
  
  .department-create-center {
    text-align: center;
    position: relative;
    top: -20px;
    margin-top: 0px;
  }

  .red {
    color: red;
  }
  
  .left-align {
    text-align: left;
  }
  
  </style>
  