<template>
  <div id="modal__applyCreditMemo">
    <div id="apply-cm-form-container">
      <div class="company-search">
        <div class="company-input">
          <p>Company Number: </p>
          <BFormInput v-model="compInfo.companyCode" required size="sm" class="cm-input"
            @keypress.enter="findCompanyCredits(compInfo.companyCode)"></BFormInput>
        </div>
        <BButton variant="primary" class="company-search-btn" 
        @click="findCompanyCredits(compInfo.companyCode)" 
        >Search</BButton>
        <div class="company-info" v-if="searchComplete">{{ compInfo.CompanyName }}</div>
        <div class="company-info" v-if="searchComplete">{{ compInfo.Street }}</div>
        <div class="company-info" v-if="searchComplete">{{ compInfo.City }}</div>
        <div class="company-info" v-if="searchComplete">{{ compInfo.State }}</div>
        <div class="company-info" v-if="searchComplete">{{ compInfo.Zip }}</div>
        <div class="company-info" v-if="searchComplete">Total Available Credit: {{ currency(compInfo.TotalCredit) }}</div>
      </div>
      <div v-if="searchComplete">
				<div class="cm-details-container">
					<b>Credit Memo Details:</b>
					<div class="cm-details-div">
						<div
						v-for="(invoice, index) in compInfo.CM"
						:key="invoice.id"
						class="cm-vin-row"
						>
						<font-awesome-icon
							@click="handleRemoveCM(index)"
							class="edit__minus"
							icon="minus-circle"
						/>
						<b>*CM #:</b>
            <b-dropdown :text="compInfo.CM[index].CMText" class="status-change-drop">
              <b-dropdown-item v-for="(num) in compInfo.CMList" :key="num.id" @click="setCM(num, index)">
                {{ num.split(' # ')[0] }}
              </b-dropdown-item>
            </b-dropdown>
						<b class="cm-details-text">*Invoice:</b>
						<input
							required
							v-model="invoice.Invoice"
              class="input-width"
						/>
						<b class="cm-details-text">*Applied Date:</b>
            <datepicker
              class="datepicker"
              name="cmDate"
              week-start="0"
              :bootstrap-styling="true"
              v-model="invoice.AppliedDate"
              :format="format"
              :disabled-dates="disabledDates"
              :enable-time-picker="false"
              :timezone="{ timezone: 'UTC', exactMatch: true }"
              ignore-time-validation
              auto-apply
              vertical
              arrow-navigation
              required
            ></datepicker>
						<b class="cm-details-text">*Amount:</b>
						<input
							required
							v-model="invoice.AppliedAmount"
              @blur="checkAmount()"
              class="input-width"
						/>
						<b class="cm-details-text">*Note:</b>
						<textarea
							class="invoice-note"
							required
							v-model="invoice.AppliedNote"
						/>
						</div>
						<div class="edit__btns-container">
              <div @click="handleAddCM" id="edit__addVin">
                <font-awesome-icon class="edit__plus" icon="plus-circle" />
                <p>Add Additional Invoice</p>
              </div>
              <!-- <div class="override-container" @click="overrideAreYouSure" v-if="!overrideYesNo">
                <p>Use balance from another company?</p>
              </div>
              <div v-if="overrideYesNo && !useOverrideBalance">
                <BRow>
                  <p>Are you sure you want to use another company's Credit Memo balance?</p>
                  <BButton variant="secondary" class="no-button" @click="overrideYesNo = false">No</BButton>
                  <BButton variant="danger" @click="overrideCM">Yes</BButton>
                </BRow>
              </div>
              <div v-if="overrideYesNo && useOverrideBalance" class="override-div">
                <BFormInput v-model="overrideCode" size="sm" placeholder="Company Number" class="override-input"
                  @keypress.enter.native="findCompanyCredits(overrideCode)"></BFormInput>
                <BButton variant="primary" class="company-apply-btn" 
                @click="findCompanyCredits(compInfo.companyCode)" 
                >Apply</BButton>
                <BButton variant="secondary" class="company-apply-btn">Cancel</BButton>
              </div> -->
						</div>
					</div>
				</div>
      </div>
      <div class="apply-cm__footer">
				<div v-if="successMessage != ''">
					<p class="success">{{ successMessage }}</p>
				</div>
				<div v-if="errorMessage != ''">
					<p class="error">{{ errorMessage }}</p>
				</div>
        <div v-if="searchComplete">
          <BButton
            v-if="!spinners.submitting && !overrideYesNo"
            id="apply-btn"
            size="sm"
            variant="outline-primary"
            :disabled="error"
            @click="applyCreditMemo(0)"
            >Apply</BButton
          >
          <div v-else-if="!spinners.submitting && overrideYesNo">
            <BButton
              size="sm"
              variant="outline-danger"
              class="override-btn"
              @click="applyCreditMemo(1)"
              >Apply Anyway</BButton>
            <BButton size="sm" variant="outline-secondary" @click="cancelOverride">No</BButton>
          </div>
          <div v-else id="apply-btn">
            <BSpinner id="b-spinner--apply-btn__spinner"></BSpinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '@/assets/constants';
// import Datepicker from 'vuejs3-datepicker';
import { mapActions, mapGetters } from 'vuex';
import { currencyFilter } from '../../utils/helpers/currencyFilter';

export default {
  name: 'applyCreditMemo',
  components: {},
  data() {
    return {
      spinners: {
        submitting: false,
			},
      compInfo: {
				companyCode: '',
        CompanyName: '',
        Contact: '',
        Street: '',
        City: '',
        State: '',
        Zip: '',
        TotalCredit: 0.00,
        CMList: [],
        CM: [{
          AvailableCredit: 0,
          CMText: 'CM # - Balance Remaining',
          CMID: '',
          Invoice: '',
          AppliedDate: Date.now(),
          AppliedAmount: 0,
          AppliedNote: '',
          InvoiceOverride: 0
        }]
      },
			format: 'MM/dd/yyyy',
			successMessage: '',
      errorMessage: '',
      searchComplete: false,
      // disabledDates: {
      //   to: new Date(new Date().setDate(new Date().getDate() - 45)),
      //   from: new Date(new Date().setDate(new Date().getDate() + .5))
      // },
      overrideYesNo: false,
      useOverrideBalance: false,
      overrideInvoice: '',
      overrideBalance: '',
      error: false,
    };
  },
  computed: {
    ...mapGetters(['getModalData']),
    disabledDates() {
      return (date) => {
        return date < new Date(new Date().setDate(new Date().getDate() - 45)) && date >= new Date(new Date().setDate(new Date().getDate() + .5));
      }
    },
  },
  methods: {
		...mapActions([]),
    currency(value) {
      return currencyFilter(value);
    },
		applyCreditMemo(override) {
			this.spinners.submitting = true;
			this.successMessage = '';
			this.errorMessage = '';
      for (let i = 0; i < this.compInfo.CM.length; i++) {
        if (this.compInfo.CM[i].CMID == '' ||
            this.compInfo.CM[i].Invoice == '' ||
            this.compInfo.CM[i].AppliedAmount == '' ||
            this.compInfo.CM[i].AppliedAmount < 0.01 ||
            this.compInfo.CM[i].AppliedNote == '' || 
            this.compInfo.CM[i].CMText == 'CM # - Balance Remaining') 
        {
          this.spinners.submitting = false;
          this.errorMessage = "Error, please verify you have filled out all required fields and try again.";
          return;
        }
        else if (parseFloat(this.compInfo.CM[i].AppliedAmount) > parseFloat(this.compInfo.CM[i].AvailableCredit)) {
          this.spinners.submitting = false;
          console.log('applied: ', this.compInfo.CM[i].AppliedAmount);
          console.log('available: ', this.compInfo.CM[i].AvailableCredit);
          this.errorMessage = "Error, Amount cannot be greater than available credit.";
          return;
        }
        else {
          if (override == 1 && this.compInfo.CM[i].Invoice == this.overrideInvoice) {
            console.log('setting override: ', this.compInfo.CM[i].Invoice);
            this.compInfo.CM[i].InvoiceOverride = 1;
          }
          this.compInfo.CM[i].AppliedAmount = this.compInfo.CM[i].AppliedAmount.replace(',', '');
          if (i == this.compInfo.CM.length - 1) {
            console.log('compInfo: ', {...this.compInfo});
						axios
							.post(`${constants.BASE_URL}/api/credit-memo/apply-new`, { ...this.compInfo })
							.then(res => {
                this.spinners.submitting = false;
                this.searchComplete = false;
                let that = this;
                this.successMessage = 'Credit Memo applied successfully!';
                this.overrideYesNo = false;
                setTimeout(function(){ that.successMessage = '' }, 5000);
                this.compInfo.companyCode = '';
                this.compInfo.CompanyName = '';
                this.compInfo.Contact = '';
                this.compInfo.Street = '';
                this.compInfo.City = '';
                this.compInfo.State = '';
                this.compInfo.Zip = '';
                this.compInfo.TotalCredit = 0.00;
                this.compInfo.CMList = [];
                this.compInfo.CM = [{
                  AvailableCredit: 0,
                  CMText: 'CM # - Balance Remaining',
                  CMID: '',
                  Invoice: '',
                  AppliedDate: Date.now(),
                  AppliedAmount: 0,
                  AppliedNote: '',
                  InvoiceOverride: 0
                }];
                this.$socket.emit('creditMemosRefresh');
                this.$socket.emit('creditMemosRefresh', 'user');
							})
							.catch(err => {
								this.spinners.submitting = false;
                // console.log('res: ', err);
                // console.log('status: ', err.response.status);
								// console.log('err status: ', err.response.data.error);
								this.spinners.submitting = false;
                if (err.response && err.response.status == 404) {
                  this.overrideInvoice = err.response.data.data;
                  this.errorMessage = err.response.data.error + ', would you like to continue applying this invoice anyway?';
                  this.overrideYesNo = true;
                } else if (err.response && err.response.status == 406) {
                  this.overrideInvoice = err.response.data.data;
                  this.errorMessage = err.response.data.error + ', would you like to apply this Credit to this invoice anyway?';
                  this.overrideYesNo = true;
                } else if (err.response && err.response.status == 417) {
                  this.errorMessage = err.response.data.error;
                  this.overrideYesNo = false;
                } else {
                  this.errorMessage = "Error, please verify you have filled out all required fields and try again.";
                  this.overrideYesNo = false;
                }
            	});
          }
        }
      }
		},
    findCompanyCredits(code) {
      if (this.compInfo.companyCode != '') {
        this.searchComplete = false;
        this.compInfo.CMList = []
        axios
          .get(`${constants.BASE_URL}/api/credit-memo/find-company-credits/${code}`, {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          })
          .then(result => {
            this.searchComplete = true;
            this.errorMessage = '';
            this.compInfo.AvailableCredit = result.data[0].AvailableCredit;
            this.compInfo.CompanyName = result.data[0].CompanyName;
            this.compInfo.Contact = result.data[0].CompanyContact;
            this.compInfo.Street = result.data[0].CompanyStreet;
            this.compInfo.City = result.data[0].CompanyCity;
            this.compInfo.State = result.data[0].CompanyState;
            this.compInfo.Zip = result.data[0].CompanyZip;
            this.compInfo.TotalCredit = result.data[0].AvailableCredit;
            let that = this;
            if (result.data[0]) {
              for (let i = 0; i < result.data[0].Invoices.split(',').length; i++) {
                that.compInfo.CMList[i] = result.data[0].Invoices.split(',')[i];
              }
            }
          })
          .catch(err => {
            this.spinners.submitting = false;
            console.log('err: ', err)
            if (err.response) {
              console.log('err status: ', err.response.data.error);
            }
            this.searchComplete = false;
            this.errorMessage = "Error, company number does not have any approved credits available."
          });
      }
    },
    setCM(num, index) {
      this.compInfo.CM[index].CMText = num.split(' # ')[0];
      this.compInfo.CM[index].CMID = parseInt(num.split(' # ')[1]);
      this.compInfo.CM[index].AvailableCredit = num.split('$')[1].split('/')[0];
      this.checkAmount();
    },
    checkAmount() {
      let amountPerCM = [];
      this.error = false;
      this.errorMessage = '';
      for (let i = 0; i < this.compInfo.CM.length; i++) {
        let tempCM = undefined;
        if (amountPerCM.length > 0) {
          tempCM = amountPerCM.findIndex(cm => cm.CMNum == this.compInfo.CM[i].CMText);
        }
        if (tempCM >= 0 && amountPerCM != [] && this.compInfo.CM[i].CMText == amountPerCM[tempCM].CMNum) {
          amountPerCM[tempCM].Amount += parseFloat(this.compInfo.CM[i].AppliedAmount).toFixed(2);
        } else {
          amountPerCM.push({
            CMNum: this.compInfo.CM[i].CMText,
            Remain: parseFloat(this.compInfo.CM[i].AvailableCredit).toFixed(2),
            Amount: parseFloat(this.compInfo.CM[i].AppliedAmount).toFixed(2)
          })
        }
      }
      for (let x = 0; x < amountPerCM.length; x++) {
        if (parseFloat(parseFloat(amountPerCM[x].Amount).toFixed(2)) > parseFloat(parseFloat(amountPerCM[x].Remain).toFixed(2))) {
          this.error = true;
          this.errorMessage = 'Total Amounts used cannot be greater than what is available in CM# ' + amountPerCM[x].CMNum + '. Applying: $' + parseFloat(amountPerCM[x].Amount).toFixed(2);
        }
      }
    },
		handleAddCM() {
			this.compInfo.CM.push({
        AvailableCredit: 0,
        CMText: 'CM # - Balance Remaining',
        CMID: '',
        Invoice: '',
        AppliedDate: Date.now(),
        AppliedAmount: 0,
        AppliedNote: '',
        InvoiceOverride: 0
			})
		},
    handleRemoveCM(index) {
      this.compInfo.CM.splice(index, 1);
      this.checkAmount();
    },
    overrideAreYouSure() {
      this.overrideYesNo = true;
    },
    overrideCM() {
      this.useOverrideBalance = true;
      console.log('overriding...')
    },
    cancelOverride() {
      this.overrideYesNo = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index';

#modal__applyCreditMemo {
  min-height: 100%;
  min-width: 100%;
}

.modal-lg {
	max-width: 1000px;
}

#apply-cm__title {
  margin: 10px 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

#apply-cm-form-container {
  width: 90%;
  margin: 0 auto;
}

.apply-cm__footer {
  height: 14vh;
  width: 100%;
  @include F($d: column);
  textarea {
    outline: none;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    font-size: 0.9rem;
    color: #363636;
    font-weight: 500;
    padding: 5px 10px;
    resize: none;
    width: 100%;
    height: 90%;
    margin-bottom: 10px;
  }
  #apply-btn {
    margin: 5px 0 10px auto;
  }

  #b-spinner--apply-btn__spinner {
    color: #5aaaff;
  }
}

.cm-input {
  margin-bottom: 10px;
  max-width: 200px;
	height: calc(1.5em + .75em + 10px);
}

.cm-textarea {
	margin-bottom: 10px;
}

p {
	font-size: 1rem;
}

.cm-details-container {
  @include F(center, flex-start, column);
  width: 101%;
  margin: 10px -15px;
  b {
    margin: 0 5px 0 5px;
  }
  input {
    background: none;
		border: 1px solid lightgray;
		border-radius: 5px;
		margin-right: 5px;
		padding: 5px;
    outline: none;
  }
}

.cm-details-div {
	@include F(center, flex-start, column);
	width: 100%;
}

.cm-vin-row {
  @include F(flex-start, center);
  background: #eee;
  margin: 5px 0;
  width: 100%;
  b {
    font-weight: 700;
  }
  input {
    font-size: 0.8rem;
  }
}

.edit__plus {
  color: green;
  cursor: pointer;
  margin: 0 5px 0;
  font-size: 0.8rem;
}

.edit__minus {
  color: firebrick;
  cursor: pointer;
  margin: 0 5px;
  font-size: 0.8rem;
}

.edit__btns-container {
  margin: 10px 0 0;
  padding: 0 10px 0 0;
  @include F($j: space-between);
  width: 100%;
}

#edit__addVin {
  cursor: pointer;
  @include F();
  p {
    font-weight: 600;
  }
}

.invoice-note {
	max-width: 200px;
	background: #eee;
	border: 1px solid lightgray;
}

.success {
	color: green;
}

.error {
	color: red;
}

.company-search {
  display: flex;
}

.company-search-btn {
  margin-left: 20px;
  margin-top: 25px;
  max-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 40px;
}

.company-info {
  margin-left: 50px;
  margin-top: 25px;
}

.cm-details-text {
  margin-left: 200px;
}

.datepicker {
  max-width: 125px;
}

.input-width {
  max-width: 125px;
}

.override-container {
  cursor: pointer;
  p {
    font-weight: 500;
  }
}

.no-button {
  margin-left: 10px;
  margin-right: 10px;
}

.override-div {
  display: flex;
}

.override-input {
  height: 40px;
}

.company-apply-btn {
  height: 40px;
  margin-left: 10px;
}

.override-btn {
  margin-right: 10px;
}
</style>
